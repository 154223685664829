import Checkbox from '@alexis/components/Checkbox';
import { clsx } from '@alexis/helpers/clsx';
import { getCookie } from '@alexis/helpers/cookie';
import { dateApiFormat, getLocalDate } from '@alexis/helpers/date';
import { genzoTrackActionEvent } from '@alexis/helpers/genzo';
import { localStorageGet, localStorageSave } from '@alexis/helpers/localStorage';
import { appendSearchParams } from '@alexis/helpers/searchParam';
import type { CabinClass } from 'flights/cabinClass';
import type { FlightSearch } from 'flights/flightSearch';
import type { FlightSearchType } from 'flights/flightSearchType';
import { cloneDeep } from 'lodash-es';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { translateNumber } from '@wego/alexis/helpers/translation';

import FlightSearchTypePicker from '@components/flights/FlightSearchTypePicker';
import LegSearch from '@components/flights/LegSearch';

import {
  MAXIMUM_PASSENGER_LIMIT,
  FLIGHT_SEARCH_2_V2_2_CORE_CONFIG_KEY,
  DIRECT_ONLY_PARAM,
} from '@constants/flight';

import { GlobalContext } from '@context/GlobalContext';

import {
  getUpdatedLegSearchesWhenAddingFlight,
  getUpdatedLegSearchesWhenLegSearchFieldChange,
  getPreCheckedStatusForFlightComparisonProviders,
  updateFlightComparisonProviderCheckedStatus,
} from '@helpers/flight';
import { dateGreatEscapeFormat, getTomorrowDateInMilliSeconds } from '@helpers/flights/date';

import { useGetFlightComparisonProviders } from '@hooks/api/flights/useGetFlightComparisonProviders';
import { useGetUpdateFlightRecentSearchDetails } from '@hooks/api/flights/useGetUpdateFlightRecentSearchDetails';
import { useGetPlace } from '@hooks/api/places/useGetPlace';
import useFlightSearchResultsDatadog from '@hooks/useFlightSearchResultsDatadog';
import useLocaleParam from '@hooks/useLocaleParam';
import usePreferredPaymentMethods from '@hooks/usePreferredPaymentMethods';

import {
  getCurrencyState,
  getCurrentSiteState,
  getIsRtlState,
  getLocaleState,
  getTranslationsState,
  getUserState,
} from '@redux/selectors';

import { FLIGHT_RECENT_SEARCH_LOCAL_STORAGE_KEY } from '../../constants/localStorage';
import {
  cabinClassDisplay,
  constructGreatEscapeLink,
  convertLegSearchesToParam,
  getComparisonProviderLabelText,
  getNearestMulticityLegSearchOutboundDateMilliseconds,
  passengerCountDisplay,
} from '../../helpers/flight';
import { flightsRoute } from '../../helpers/routeTranslation';
import styles from '../../styles/components/flights/FlightSearchForm.module.scss';

interface FlightSearchFormProps {
  geolocationCoordinates: GeolocationCoordinates | undefined;
  nearestCityByIP: Place | undefined;
  pageViewId: string;
  paymentMethods: Array<HomepageFlightsPaymentMethod>;
  wegoAnalyticsClientId: string | undefined;
  wegoAnalyticsClientSessionId: string | undefined;
  setSelectedPaymentTypes: (paymentMethods: number[]) => void;
}

const FlightSearchForm: React.FC<FlightSearchFormProps> = ({
  geolocationCoordinates,
  nearestCityByIP,
  pageViewId,
  paymentMethods,
  wegoAnalyticsClientId,
  wegoAnalyticsClientSessionId,
  setSelectedPaymentTypes,
}) => {
  const user = useSelector(getUserState);
  const translations = useSelector(getTranslationsState);
  const locale = useSelector(getLocaleState);
  const currency = useSelector(getCurrencyState);
  const currentSite = useSelector(getCurrentSiteState);
  const isRtl = useSelector(getIsRtlState);

  const { userCentricCoreConfigs } = useContext(GlobalContext);

  const localeParam = useLocaleParam();

  const flightSearchRefs = useRef<Array<LegSearchHandles>>([]);
  const passengerPickerDropdownRef = useRef<HTMLDivElement>(null);

  const [searchType, setSearchTypes] = useState<FlightSearchType>('roundTrip');
  const [hasChangedSearchType, setHasChangedSearchTypes] = useState(false);
  const [legSearches, setLegSearches] = useState<Array<LegSearch>>([]);
  const [lastSavedInboundDateMillisecond, setLastSavedInboundDateMillisecond] = useState(0);
  const [adultCount, setAdultCount] = useState<number>(1);
  const [childCount, setChildCount] = useState<number>(0);
  const [infantCount, setInfantCount] = useState<number>(0);
  const [cabinClass, setCabinClass] = useState<CabinClass>('economy');
  const [newPaymentMethods, setNewPaymentMethods] = useState<Array<HomepageFlightsPaymentMethod>>(
    [],
  );
  const [isPassengerPickerExpanded, setIsPassengerPickerExpanded] = useState<boolean>(false);
  const [expandPassengerPickerFrom, setExpandPassengerPickerFrom] = useState<'left' | 'right'>();
  const [isCabinClassPickerExpanded, setIsCabinClassPickerExpanded] = useState<boolean>(false);
  const [isPaymentTypePickerExpanded, setIsPaymentTypePickerExpanded] = useState<boolean>(false);
  const [showMorePaymentMethods, setShowMorePaymentMethods] = useState<boolean>(false);
  const [flightComparisonProviders, setFlightComparisonProviders] = useState<
    Array<FlightComparisonProvider>
  >([]);

  const { preferredPaymentMethods, togglePreferredPaymentMethod } =
    usePreferredPaymentMethods(paymentMethods);

  const {
    recordTimeToFirstResultAction,
    recordTimeFromSearchToFirstSabreFareResult,
    startRecordingTimeToFirstFetchAction,
  } = useFlightSearchResultsDatadog();

  const dispatch = useDispatch();

  const { search, state, pathname } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const isDirectFlightOnly = searchParams.has(DIRECT_ONLY_PARAM);

  const wegoSourceSearchParam = searchParams.get('wg_source');
  const wegoMediumSearchParam = searchParams.get('wg_medium');
  const wgCampaign = getCookie('wg_campaign') || searchParams.get('wg_campaign');
  const wgInternalCampaign = searchParams.get('wg_internal_campaign');
  const bowOnlyParam = searchParams.get('bow_only');
  const tsCodeSearchParam = searchParams.get('ts_code');
  const outboundDateSearchParam = searchParams.get('outbound_date');
  const inboundDateSearchParam = searchParams.get('inbound_date');
  const departureCodeSearchParam = searchParams.get('dep_code');
  const arrivalCodeSearchParam = searchParams.get('arr_code');
  const searchTypeSearchParams = searchParams.get('trip_type');

  const flightSearch2CoreConfig = userCentricCoreConfigs.find(
    (coreConfig) => coreConfig.key === FLIGHT_SEARCH_2_V2_2_CORE_CONFIG_KEY,
  );
  const isFlightSearch2 = flightSearch2CoreConfig?.value === '1';

  const { data: flightRecentSearch } = useGetUpdateFlightRecentSearchDetails({
    enabled: Boolean(localStorageGet<FlightSearch>(FLIGHT_RECENT_SEARCH_LOCAL_STORAGE_KEY)),
    onSuccessCallback: (data) => {
      if (data) {
        localStorageSave<FlightSearch>(FLIGHT_RECENT_SEARCH_LOCAL_STORAGE_KEY, data);
      }
    },
  });

  const handleLegSearchFieldChange = useCallback<
    (legSearchIndex: number, field: FlightSearchFields, value: InboundPlace | number) => void
  >(
    (legSearchIndex, field, value) => {
      if (field === 'inboundDateMilliseconds' && searchType === 'oneWay') {
        setSearchTypes('roundTrip');
      }

      if (
        field === 'inboundPlace' ||
        field === 'outboundPlace' ||
        field === 'swapOutboundInboundPlace'
      ) {
        searchParams.delete(DIRECT_ONLY_PARAM);

        navigate({ pathname, search: searchParams.toString() }, { replace: true, state });
      }

      const updatedLegSearches = getUpdatedLegSearchesWhenLegSearchFieldChange(
        legSearches,
        legSearchIndex,
        field,
        value,
      );
      setLegSearches(updatedLegSearches);
    },
    [legSearches, searchType, searchParams, pathname, state, navigate],
  );

  const handleAddLegSearch = useCallback<() => void>(() => {
    const updatedLegSearches = getUpdatedLegSearchesWhenAddingFlight(legSearches);

    setLegSearches(updatedLegSearches);
  }, [legSearches]);

  const handleRemoveLegSearch = useCallback<(legSearchIndex: number) => void>(
    (legSearchIndex: number) => {
      const cloneLegSearches = cloneDeep<Array<LegSearch>>(legSearches);

      cloneLegSearches.splice(legSearchIndex, 1);

      setLegSearches(cloneLegSearches);
    },
    [legSearches],
  );

  const handleRemoveReturnDate = useCallback<() => void>(() => {
    setSearchTypes('oneWay');

    const updatedLegSearches = getUpdatedLegSearchesWhenLegSearchFieldChange(
      legSearches,
      0,
      'inboundDateMilliseconds',
      undefined,
    );
    setLegSearches(updatedLegSearches);
  }, [legSearches]);

  const handleDateReset = useCallback(
    (legSearchIndex: number) => {
      const cloneLegSearches = cloneDeep<Array<LegSearch>>(legSearches);
      const legSearch = cloneLegSearches[legSearchIndex];

      legSearch.outboundDateMilliseconds = undefined;
      legSearch.inboundDateMilliseconds = undefined;

      setLegSearches(cloneLegSearches);
    },
    [legSearches],
  );

  const handlePassengerPickerClick = useCallback<(event: React.MouseEvent<HTMLDivElement>) => void>(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (isRtl) {
        if (
          document.body.offsetWidth - 56 - event.currentTarget.offsetLeft >
          passengerPickerDropdownRef.current!.clientWidth
        ) {
          setExpandPassengerPickerFrom('left');
        } else {
          setExpandPassengerPickerFrom('right');
        }
      } else {
        if (
          event.currentTarget.offsetLeft + event.currentTarget.clientWidth - 56 >
          passengerPickerDropdownRef.current!.clientWidth
        ) {
          setExpandPassengerPickerFrom('right');
        } else {
          setExpandPassengerPickerFrom('left');
        }
      }

      setIsPassengerPickerExpanded(true);
    },
    [isRtl, passengerPickerDropdownRef],
  );

  const handlePassengerCountIncrease = useCallback<(type: PassengerType) => void>((type) => {
    switch (type) {
      case 'adult':
        setAdultCount((prev) => prev + 1);
        break;
      case 'child':
        setChildCount((prev) => prev + 1);
        break;
      case 'infant':
        setInfantCount((prev) => prev + 1);
        break;
    }
  }, []);

  const handlePassengerCountDecrease = useCallback<(type: PassengerType) => void>(
    (type) => {
      switch (type) {
        case 'adult':
          if (adultCount === infantCount) {
            setInfantCount((prev) => prev - 1);
          }
          setAdultCount((prev) => prev - 1);
          break;
        case 'child':
          setChildCount((prev) => prev - 1);
          break;
        case 'infant':
          setInfantCount((prev) => prev - 1);
          break;
      }
    },
    [adultCount, infantCount],
  );

  const handleCabinClassChange = useCallback<(cabinClass: CabinClass) => void>(
    (cabinClass: CabinClass) => {
      setCabinClass(cabinClass);
      setIsCabinClassPickerExpanded(false);
    },
    [],
  );

  const handleFlightComparisonProviderToggle = useCallback<
    (code: string, isSelected: boolean) => void
  >(
    (code: string, isSelected: boolean) => {
      const cloneComparisonProviders =
        cloneDeep<Array<FlightComparisonProvider>>(flightComparisonProviders);

      const comparisonProvider = cloneComparisonProviders.find(
        (cloneComparisonProvider) => cloneComparisonProvider.provider.code === code,
      );

      comparisonProvider!.preChecked = isSelected;

      updateFlightComparisonProviderCheckedStatus(code, isSelected);

      setFlightComparisonProviders(cloneComparisonProviders);
    },
    [flightComparisonProviders],
  );

  const handleFormSubmit = useCallback<(event: React.FormEvent<HTMLFormElement>) => void>(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      let hasError = false;

      for (let i = 0; i < legSearches.length; i++) {
        const flightSearchHasError = flightSearchRefs.current[i].validate();

        if (flightSearchHasError) {
          hasError = true;
        }
      }

      if (!hasError) {
        recordTimeToFirstResultAction();
        recordTimeFromSearchToFirstSabreFareResult();
        startRecordingTimeToFirstFetchAction();

        if (wegoAnalyticsClientId && wegoAnalyticsClientSessionId) {
          const eventData = {
            id: pageViewId,
            category: 'search_form',
            object: searchType.toLocaleLowerCase(),
            action: 'search_cta',
            value: 'successful',
          };

          genzoTrackActionEvent(
            API_BASE_URL,
            wegoAnalyticsClientId,
            wegoAnalyticsClientSessionId,
            user?.userHash,
            eventData,
          );
        }

        const searchParams = new URLSearchParams(search);

        searchParams.append('sort', 'score');
        searchParams.append('order', 'desc');

        searchParams.delete('dep_code');
        searchParams.delete('arr_code');
        searchParams.delete('outbound_date');
        searchParams.delete('inbound_date');
        searchParams.delete('open_calendar');
        searchParams.delete('trip_type');

        const paymentMethodInStorage =
          localStorageGet<{ [key: string]: boolean }>('flightSearchPaymentMethods') || {};

        const paymentMethodParams =
          preferredPaymentMethods.length > 0
            ? preferredPaymentMethods.join(',')
            : Object.keys(paymentMethodInStorage).join(',');

        searchParams.set('payment_methods', paymentMethodParams);

        if (isDirectFlightOnly) {
          searchParams.append('stops', '0');
        }

        if (
          legSearches[0].inboundPlace === 'Anywhere' &&
          searchType !== 'multiCity' &&
          wegoAnalyticsClientSessionId &&
          wegoAnalyticsClientId
        ) {
          const eventData = {
            id: pageViewId,
            category: 'search_form',
            object: 'search_cta',
            action: 'click',
            value: 'explore_anywhere',
          };

          genzoTrackActionEvent(
            API_BASE_URL,
            wegoAnalyticsClientId,
            wegoAnalyticsClientSessionId,
            user?.userHash,
            eventData,
          );

          const greatEscapeOutboundDate = dateGreatEscapeFormat(
            new Date(legSearches[0].outboundDateMilliseconds!),
          );

          const greatEscapeInboundDate =
            searchType === 'roundTrip'
              ? dateGreatEscapeFormat(new Date(legSearches[0].inboundDateMilliseconds!))
              : undefined;

          const greatEscapeLink = constructGreatEscapeLink(
            currentSite.host,
            wegoAnalyticsClientSessionId,
            {
              ...(wegoSourceSearchParam && { wg_source: wegoSourceSearchParam }),
              ...(wegoMediumSearchParam && { wg_medium: wegoMediumSearchParam }),
              ...(wgCampaign && { wg_campaign: wgCampaign }),
              ...(tsCodeSearchParam && { ts_code: tsCodeSearchParam }),
              ...(wgInternalCampaign && { wg_internal_campaign: wgInternalCampaign }),
              ...(bowOnlyParam && { bow_only: bowOnlyParam }),
            },
            greatEscapeOutboundDate,
            greatEscapeInboundDate,
            legSearches[0].outboundPlace as Place,
            adultCount,
            childCount,
            infantCount,
            currency.code,
            isDirectFlightOnly,
            paymentMethodParams,
            searchType,
          );

          window.open(greatEscapeLink, '_self');

          return;
        }

        const legParam = convertLegSearchesToParam(legSearches);

        localStorageSave<FlightSearch>(FLIGHT_RECENT_SEARCH_LOCAL_STORAGE_KEY, {
          searchType,
          legSearches,
          adultCount,
          childCount,
          infantCount,
          cabinClass,
          locale,
        });

        const updatedSearchParams = searchParams.toString();

        const searchResultPathname = `${flightsRoute(
          currentSite,
          localeParam,
        )}/searches/${legParam}/${cabinClass}/${adultCount}a:${childCount}c:${infantCount}i`.toLowerCase();

        if (flightComparisonProviders.length > 0 && flightComparisonProviders[0].preChecked) {
          const searchParams = {
            currency_code: currency.code,
            cabin: cabinClass,
            outbound_date: dateApiFormat(new Date(legSearches[0].outboundDateMilliseconds!)),
            ...(searchType === 'roundTrip' && {
              inbound_date: dateApiFormat(new Date(legSearches[0].inboundDateMilliseconds!)),
            }),
            adults_count: adultCount,
            ...(childCount > 0 && { children_count: childCount }),
            ...(infantCount > 0 && { infants_count: infantCount }),
            wc: wegoAnalyticsClientId,
            ws: wegoAnalyticsClientSessionId,
          };

          const handoffUrl = appendSearchParams(
            flightComparisonProviders[0].handoffUrl,
            searchParams,
          );

          // setTimeout is to prevent popup blocker
          setTimeout(() => {
            window.location.href = handoffUrl;
          }, 1);

          window.open(
            `${window.location.origin}${searchResultPathname}${
              updatedSearchParams ? `?${updatedSearchParams}` : ''
            }`,
            '_blank',
          );
        } else {
          navigate(
            { pathname: searchResultPathname.toLowerCase(), search: updatedSearchParams },
            { state },
          );
          // TODO: fully use navigate when flights is fully reactjs
          // navigate({ pathname: searchResultPathname.toLowerCase(), search: updatedSearchParams }, { state });
        }
      } else if (wegoAnalyticsClientId && wegoAnalyticsClientSessionId) {
        const eventData = {
          id: pageViewId,
          category: 'search_form',
          object: searchType.toLocaleLowerCase(),
          action: 'search_cta',
          value: 'unsuccessful',
        };

        genzoTrackActionEvent(
          API_BASE_URL,
          wegoAnalyticsClientId,
          wegoAnalyticsClientSessionId,
          user?.userHash,
          eventData,
        );
      }
    },
    [
      locale,
      legSearches,
      search,
      preferredPaymentMethods,
      isDirectFlightOnly,
      dispatch,
      searchType,
      adultCount,
      childCount,
      infantCount,
      cabinClass,
      currentSite,
      localeParam,
      flightComparisonProviders,
      currency.code,
      pageViewId,
      user?.userHash,
      wegoAnalyticsClientId,
      wegoAnalyticsClientSessionId,
      navigate,
      state,
      tsCodeSearchParam,
      wegoMediumSearchParam,
      wegoSourceSearchParam,
      wgCampaign,
      wgInternalCampaign,
      bowOnlyParam,
    ],
  );

  useEffect(() => {
    setSelectedPaymentTypes(preferredPaymentMethods);
  }, [preferredPaymentMethods]);

  // Save the last inbound date for round trip
  useEffect(() => {
    if (legSearches[0]?.inboundDateMilliseconds) {
      setLastSavedInboundDateMillisecond(legSearches[0].inboundDateMilliseconds);
    }
  }, [legSearches]);

  // Set all picker focus to false if body is clicked
  useEffect(() => {
    if (isPassengerPickerExpanded || isCabinClassPickerExpanded || isPaymentTypePickerExpanded) {
      const handleBodyOnClick = (): void => {
        if (isPassengerPickerExpanded) {
          setIsPassengerPickerExpanded(false);
        }

        if (isCabinClassPickerExpanded) {
          setIsCabinClassPickerExpanded(false);
        }

        if (isPaymentTypePickerExpanded) {
          setIsPaymentTypePickerExpanded(false);
        }
      };

      document.body.addEventListener('click', handleBodyOnClick);

      return () => {
        document.body.removeEventListener('click', handleBodyOnClick);
      };
    }
  }, [isPassengerPickerExpanded, isCabinClassPickerExpanded, isPaymentTypePickerExpanded]);

  // Get outbound place and inbound place from params
  const { data: outboundPlaceFromParam } = useGetPlace(departureCodeSearchParam, {
    enabled: !!departureCodeSearchParam,
  });
  const { data: inboundPlaceFromParam } = useGetPlace(arrivalCodeSearchParam, {
    enabled: !!arrivalCodeSearchParam,
  });

  // Fill up form with information from params, OR nearest city by IP as outbound place or from flight recent search
  useEffect(() => {
    const limitFromMilliseconds = new Date().setHours(0, 0, 0, 0);

    const limitTillDate = new Date();
    limitTillDate.setHours(0, 0, 0, 0);
    limitTillDate.setDate(limitTillDate.getDate() - 1);
    const limitToMilliseconds = limitTillDate.setFullYear(limitTillDate.getFullYear() + 1);

    if (
      inboundPlaceFromParam ||
      outboundPlaceFromParam ||
      outboundDateSearchParam ||
      inboundDateSearchParam ||
      searchTypeSearchParams
    ) {
      const initialLegSearches: LegSearch[] = [
        {
          inboundDateMilliseconds: undefined,
          inboundPlace: inboundPlaceFromParam,
          outboundDateMilliseconds: getTomorrowDateInMilliSeconds(),
          outboundPlace: outboundPlaceFromParam || nearestCityByIP,
        },
      ];

      if (outboundDateSearchParam) {
        let selectedOutboundDateMilliseconds = getLocalDate(outboundDateSearchParam).getTime();

        selectedOutboundDateMilliseconds = Math.max(
          limitFromMilliseconds,
          selectedOutboundDateMilliseconds,
        );
        selectedOutboundDateMilliseconds = Math.min(
          limitToMilliseconds,
          selectedOutboundDateMilliseconds,
        );

        initialLegSearches[0].outboundDateMilliseconds = selectedOutboundDateMilliseconds;

        if (
          !!initialLegSearches[0].inboundDateMilliseconds &&
          selectedOutboundDateMilliseconds > initialLegSearches[0].inboundDateMilliseconds
        ) {
          initialLegSearches[0].inboundDateMilliseconds = undefined;
        }
      }

      if (inboundDateSearchParam) {
        let selectedInboundDateMilliseconds = getLocalDate(inboundDateSearchParam).getTime();
        selectedInboundDateMilliseconds = Math.max(
          limitFromMilliseconds,
          selectedInboundDateMilliseconds,
        );
        selectedInboundDateMilliseconds = Math.min(
          limitToMilliseconds,
          selectedInboundDateMilliseconds,
        );

        initialLegSearches[0].inboundDateMilliseconds = selectedInboundDateMilliseconds;

        if (
          !!initialLegSearches[0].outboundDateMilliseconds &&
          initialLegSearches[0].outboundDateMilliseconds > selectedInboundDateMilliseconds
        ) {
          initialLegSearches[0].inboundDateMilliseconds = undefined;
        }
      }

      if (searchTypeSearchParams === 'oneway') {
        setSearchTypes('oneWay');
        initialLegSearches[0].inboundDateMilliseconds = undefined;
      } else if (searchTypeSearchParams === 'roundtrip') {
        setSearchTypes('roundTrip');
      }

      setLegSearches(initialLegSearches);

      return;
    }

    if (hasChangedSearchType) {
      return;
    }

    if (
      !!flightRecentSearch &&
      flightRecentSearch.legSearches[0].outboundDateMilliseconds! >= limitFromMilliseconds
    ) {
      setLegSearches(flightRecentSearch.legSearches);
      setAdultCount(flightRecentSearch.adultCount);
      setChildCount(flightRecentSearch.childCount);
      setInfantCount(flightRecentSearch.infantCount);
      setCabinClass(flightRecentSearch.cabinClass);
      setSearchTypes(flightRecentSearch.searchType);
    } else {
      const flightSearches: Array<LegSearch> = [
        {
          outboundPlace: nearestCityByIP,
          inboundPlace: undefined,
          outboundDateMilliseconds: getTomorrowDateInMilliSeconds(),
          inboundDateMilliseconds: undefined,
        },
      ];
      setLegSearches(flightSearches);
    }
  }, [
    flightRecentSearch,
    inboundDateSearchParam,
    inboundPlaceFromParam,
    nearestCityByIP,
    outboundDateSearchParam,
    outboundPlaceFromParam,
    searchTypeSearchParams,
    hasChangedSearchType,
  ]);

  const handleOneWayClick = useCallback(() => {
    setSearchTypes('oneWay');
    setHasChangedSearchTypes(true);

    if (legSearches.length > 0) {
      const cloneLegSearches = cloneDeep<Array<LegSearch>>(legSearches);
      const newLegSearches = cloneLegSearches.slice(0, 1);
      newLegSearches[0].inboundDateMilliseconds = undefined;
      setLegSearches(newLegSearches);
    }
  }, [legSearches]);

  const handleRoundTripClick = useCallback(() => {
    setSearchTypes('roundTrip');
    setHasChangedSearchTypes(true);

    if (legSearches.length > 0) {
      const cloneLegSearches = cloneDeep<Array<LegSearch>>(legSearches);
      const newLegSearches = cloneLegSearches.slice(0, 1);
      newLegSearches[0].inboundDateMilliseconds = lastSavedInboundDateMillisecond;
      setLegSearches(newLegSearches);
    }
  }, [lastSavedInboundDateMillisecond, legSearches]);

  const handleMulticityClick = useCallback(() => {
    setSearchTypes('multiCity');
    setHasChangedSearchTypes(true);

    if (legSearches.length === 1) {
      const cloneLegSearches = cloneDeep<Array<LegSearch>>(legSearches);

      cloneLegSearches[0].inboundDateMilliseconds = undefined;

      cloneLegSearches.push(
        {
          outboundPlace:
            cloneLegSearches[0].inboundPlace !== 'Anywhere'
              ? cloneLegSearches[0].inboundPlace
              : undefined,
          inboundPlace: undefined,
          outboundDateMilliseconds: undefined,
          inboundDateMilliseconds: undefined,
        },
        {
          outboundPlace: undefined,
          inboundPlace: undefined,
          outboundDateMilliseconds: undefined,
          inboundDateMilliseconds: undefined,
        },
      );

      setLegSearches(cloneLegSearches);
    }
  }, [legSearches]);

  const handleSearchTypeChange = useCallback(
    (searchType: FlightSearchType) => {
      if (searchType === 'oneWay') {
        return handleOneWayClick();
      }

      if (searchType === 'roundTrip') {
        return handleRoundTripClick();
      }

      if (searchType === 'multiCity') {
        return handleMulticityClick();
      }
    },
    [handleMulticityClick, handleOneWayClick, handleRoundTripClick],
  );

  // Initialize payment methods
  useEffect(() => {
    const clonePaymentMethods = cloneDeep<Array<HomepageFlightsPaymentMethod>>(paymentMethods);

    const localStoragePaymentMethods = localStorageGet<{ [key: string]: boolean }>(
      'flightSearchPaymentMethods',
    );

    if (localStoragePaymentMethods) {
      clonePaymentMethods.forEach((clonePaymentMethod) => {
        const localStoragePaymentMethod = localStoragePaymentMethods[clonePaymentMethod.id];

        if (localStoragePaymentMethod !== undefined) {
          clonePaymentMethod.isPopular = localStoragePaymentMethod;
        }
      });
    }

    const sortedPaymentMethods = clonePaymentMethods.sort((aPaymentMethod, bPaymentMethod) => {
      if (aPaymentMethod.isPopular && !bPaymentMethod.isPopular) {
        return -1;
      } else if (!aPaymentMethod.isPopular && bPaymentMethod.isPopular) {
        return 1;
      }
      return 0;
    });

    setNewPaymentMethods(sortedPaymentMethods);
  }, [paymentMethods]);

  // Get flight comparison providers
  useGetFlightComparisonProviders(
    {
      departureAirportCode: (legSearches[0]?.outboundPlace as Place)?.airportCode,
      departureCityCode: legSearches[0]?.outboundPlace?.cityCode || '',
      departureCountryCode: legSearches[0]?.outboundPlace?.countryCode || '',
      departureDate: dateApiFormat(new Date(legSearches[0]?.outboundDateMilliseconds || '')),
      arrivalAirportCode: (legSearches[0]?.inboundPlace as Place)?.airportCode,
      arrivalCityCode: (legSearches[0]?.inboundPlace as Place)?.cityCode || '',
      arrivalCountryCode: (legSearches[0]?.inboundPlace as Place)?.countryCode || '',
      returnDate: legSearches[0]?.inboundDateMilliseconds
        ? dateApiFormat(new Date(legSearches[0]?.inboundDateMilliseconds))
        : undefined,
      placementType: 'home',
    },
    {
      enabled:
        searchType !== 'multiCity' &&
        !!legSearches[0] &&
        !!legSearches[0].outboundPlace &&
        !!legSearches[0].inboundPlace &&
        !!legSearches[0].outboundDateMilliseconds &&
        !bowOnlyParam,
      onSuccessCallback: (flightComparisonProviders) => {
        getPreCheckedStatusForFlightComparisonProviders(flightComparisonProviders);

        setFlightComparisonProviders(flightComparisonProviders);
      },
      onErrorCallback: () => {
        setFlightComparisonProviders([]);
      },
    },
  );

  useEffect(() => {
    if (searchType === 'multiCity' && flightComparisonProviders.length) {
      setFlightComparisonProviders([]);
    }
  }, [searchType, flightComparisonProviders.length]);

  const handleDirectFlightsOnlyCheckBoxToggle = () => {
    if (wegoAnalyticsClientSessionId && wegoAnalyticsClientId) {
      const eventData = {
        id: pageViewId,
        category: 'search_form',
        object: searchType,
        action: 'direct_flights_only',
        value: (!isDirectFlightOnly).toString(),
      };

      genzoTrackActionEvent(
        API_BASE_URL,
        wegoAnalyticsClientId,
        wegoAnalyticsClientSessionId,
        user?.userHash,
        eventData,
      );
    }

    if (isDirectFlightOnly) {
      searchParams.delete(DIRECT_ONLY_PARAM);
    } else {
      searchParams.set(DIRECT_ONLY_PARAM, 'true');
    }

    navigate({ pathname, search: searchParams.toString() }, { replace: true, state });
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLButtonElement> = (e) => {
    if (e.key === 'Tab' && e.shiftKey) {
      e.preventDefault();

      const toDatePicker = document.getElementById('to-input-container');

      if (toDatePicker) {
        toDatePicker.focus();
      } else {
        document.getElementById('from-input-container')?.focus();
      }
    }
  };

  return (
    <form
      className={clsx(
        styles.container,
        isRtl && styles.rtl,
        isFlightSearch2 && styles.flightSearch2,
      )}
      onSubmit={handleFormSubmit}
      noValidate={true}
    >
      <div className={styles.searchTypesAndAddLegSearchContainer}>
        <FlightSearchTypePicker
          searchType={searchType}
          onSearchTypeChange={handleSearchTypeChange}
        />
      </div>

      <div className={styles.legSearches}>
        {legSearches.map((legSearch, index) => (
          <LegSearch
            clientId={wegoAnalyticsClientId}
            clientSessionId={wegoAnalyticsClientSessionId}
            geolocationCoordinates={geolocationCoordinates}
            isFromHomepage
            isRemoveLegSearchDisabled={legSearches.length < 3}
            key={index}
            legSearch={legSearch}
            legSearchIndex={index}
            nextLegSearchOutboundDateMilliseconds={
              searchType === 'multiCity'
                ? getNearestMulticityLegSearchOutboundDateMilliseconds('next', index, legSearches)
                : undefined
            }
            onLegSearchFieldChange={handleLegSearchFieldChange}
            onRemoveLegSearchClick={handleRemoveLegSearch}
            onRemoveReturnDateClick={handleRemoveReturnDate}
            onDateReset={() => handleDateReset(index)}
            pageViewId={pageViewId}
            previousLegSearchOutboundDateMilliseconds={
              searchType === 'multiCity'
                ? getNearestMulticityLegSearchOutboundDateMilliseconds(
                    'previous',
                    index,
                    legSearches,
                  )
                : undefined
            }
            ref={(flightSearchHandles) => (flightSearchRefs.current[index] = flightSearchHandles!)}
            searchType={searchType}
            passengerCount={adultCount + childCount + infantCount}
          />
        ))}
      </div>

      <div className={clsx(searchType === 'multiCity' && styles.flightBtnFilterContainer)}>
        <div className={styles.searchTypesAndAddLegSearchContainer}>
          {searchType === 'multiCity' ? (
            <button
              data-pw='multicitySearch_addLegBtn'
              className={styles.addLegSearch}
              disabled={legSearches.length === 6}
              onClick={handleAddLegSearch}
              type='button'
            >
              {translations.add_flight}
              <div className={clsx(styles.tooltip, styles.bottom)}>
                {translations.multicity_search_add_flights}
              </div>
            </button>
          ) : null}
        </div>
        <div className={styles.filtersAndSearchButton}>
          <div className={clsx(styles.filters)}>
            {searchType !== 'multiCity' && (
              <div className={styles.checkboxFilters}>
                <div
                  className={styles.checkboxFilter}
                  onClick={handleDirectFlightsOnlyCheckBoxToggle}
                >
                  <Checkbox className={styles.checkbox} isChecked={isDirectFlightOnly} />
                  {translations.direct_flight_only}
                </div>
              </div>
            )}

            <div className={styles.buttonFilters}>
              {/* Passenger picker */}
              <div
                data-pw='searchForm_noOfPassenger'
                data-testid='passengers-input'
                className={clsx(styles.buttonFilter, isPassengerPickerExpanded && styles.expanded)}
                onClick={handlePassengerPickerClick}
              >
                {passengerCountDisplay(adultCount, childCount, infantCount, translations, locale)}
                <i className={clsx(styles.triangle, styles.down)}></i>

                <div
                  data-testid='passenger-picker'
                  className={clsx(
                    styles.passengerPickerDropdown,
                    expandPassengerPickerFrom === 'left' && styles.openFromLeft,
                    expandPassengerPickerFrom === 'right' && styles.openFromRight,
                  )}
                  onClick={(e) => e.stopPropagation()}
                  ref={passengerPickerDropdownRef}
                >
                  {/* Adult */}
                  <div data-pw='noOfPassengers_adults' className={styles.passenger}>
                    <div className={styles.type}>
                      <i className={clsx(styles.icon, styles.adultGrey)}></i>

                      <div>
                        <div className={styles.label}>{translations.Adults}</div>
                        <div className={styles.requirement}>{translations.adult_age}</div>
                      </div>
                    </div>

                    <div className={styles.stepperContainer}>
                      <button
                        aria-label='Remove'
                        data-testid='remove-passenger'
                        className={styles.minusSign}
                        type='button'
                        disabled={adultCount < 2}
                        onClick={() => handlePassengerCountDecrease('adult')}
                      ></button>
                      {/* key is used to fix visual bug happened on safari https://wego.slack.com/archives/CDP50BYVD/p1714542077071519 */}
                      <div key={adultCount} className={styles.counter}>
                        {translateNumber(adultCount, locale === 'fa')}
                      </div>
                      <button
                        aria-label='Add'
                        data-testid='add-passenger'
                        className={styles.plusSign}
                        type='button'
                        disabled={adultCount + childCount + infantCount === MAXIMUM_PASSENGER_LIMIT}
                        onClick={() => handlePassengerCountIncrease('adult')}
                      ></button>
                    </div>
                  </div>

                  {/* Child */}
                  <div data-pw='noOfPassengers_children' className={styles.passenger}>
                    <div className={styles.type}>
                      <i className={clsx(styles.icon, styles.childGrey)}></i>

                      <div>
                        <div className={styles.label}>{translations.children}</div>
                        <div className={styles.requirement}>{translations.child_age}</div>
                      </div>
                    </div>

                    <div className={styles.stepperContainer}>
                      <button
                        aria-label='Remove'
                        data-testid='remove-passenger'
                        className={styles.minusSign}
                        type='button'
                        disabled={childCount < 1}
                        onClick={() => handlePassengerCountDecrease('child')}
                      ></button>
                      {/* key is used to fix visual bug happened on safari https://wego.slack.com/archives/CDP50BYVD/p1714542077071519 */}
                      <div key={childCount} className={styles.counter}>
                        {translateNumber(childCount, locale === 'fa')}
                      </div>
                      <button
                        aria-label='Add'
                        data-testid='add-passenger'
                        className={styles.plusSign}
                        type='button'
                        disabled={adultCount + childCount + infantCount === MAXIMUM_PASSENGER_LIMIT}
                        onClick={() => handlePassengerCountIncrease('child')}
                      ></button>
                    </div>
                  </div>

                  {/* Infant */}
                  <div data-pw='noOfPassengers_infants' className={styles.passenger}>
                    <div className={styles.type}>
                      <i className={clsx(styles.icon, styles.infantGrey)}></i>

                      <div>
                        <div className={styles.label}>{translations.infants}</div>
                        <div className={styles.requirement}>{translations.infant_age}</div>
                      </div>
                    </div>

                    <div className={styles.stepperContainer}>
                      <button
                        aria-label='Remove'
                        data-testid='remove-passenger'
                        className={styles.minusSign}
                        type='button'
                        disabled={infantCount < 1}
                        onClick={() => handlePassengerCountDecrease('infant')}
                      ></button>
                      {/* key is used to fix visual bug happened on safari https://wego.slack.com/archives/CDP50BYVD/p1714542077071519 */}
                      <div key={infantCount} className={styles.counter}>
                        {translateNumber(infantCount, locale === 'fa')}
                      </div>
                      <button
                        aria-label='Add'
                        data-testid='add-passenger'
                        className={styles.plusSign}
                        type='button'
                        disabled={
                          infantCount === adultCount ||
                          adultCount + childCount + infantCount === MAXIMUM_PASSENGER_LIMIT
                        }
                        onClick={() => handlePassengerCountIncrease('infant')}
                      ></button>
                    </div>
                  </div>

                  <div className={styles.applyContainer}>
                    <button
                      data-pw='noOfPassengers_applyBtn'
                      onClick={() => setIsPassengerPickerExpanded(false)}
                      type='button'
                    >
                      {((translations.apply as string) || '').toLowerCase()}
                    </button>
                  </div>
                </div>
              </div>

              {/* Cabin class picker */}
              <div
                data-pw='searchForm_cabinClass'
                data-testid='cabin-class-input'
                className={clsx(styles.buttonFilter, isCabinClassPickerExpanded && styles.expanded)}
                onClick={() => setIsCabinClassPickerExpanded(true)}
              >
                {cabinClassDisplay(cabinClass, translations)}
                <i className={clsx(styles.triangle, styles.down)}></i>

                <div
                  data-pw='searchForm_cabinClassPicker'
                  className={styles.cabinClassPickerDropdown}
                  onClick={(e) => e.stopPropagation()}
                >
                  <div
                    className={clsx(styles.cabinClass, cabinClass === 'economy' && styles.selected)}
                    onClick={() => handleCabinClassChange('economy')}
                  >
                    {translations.economy}
                  </div>
                  <div
                    className={clsx(
                      styles.cabinClass,
                      cabinClass === 'premium_economy' && styles.selected,
                    )}
                    onClick={() => handleCabinClassChange('premium_economy')}
                  >
                    {translations.premium_economy}
                  </div>
                  <div
                    className={clsx(
                      styles.cabinClass,
                      cabinClass === 'business' && styles.selected,
                    )}
                    onClick={() => handleCabinClassChange('business')}
                  >
                    {translations.business_class}
                  </div>
                  <div
                    className={clsx(styles.cabinClass, cabinClass === 'first' && styles.selected)}
                    onClick={() => handleCabinClassChange('first')}
                  >
                    {translations.first}
                  </div>
                </div>
              </div>

              {/* Payment type picker */}
              <div
                data-pw='searchForm_paymentMethods'
                className={clsx(
                  styles.buttonFilter,
                  isPaymentTypePickerExpanded && styles.expanded,
                )}
                onClick={() => setIsPaymentTypePickerExpanded(true)}
              >
                <span data-testid='payment-methods-input'>
                  {preferredPaymentMethods.length > 0 && preferredPaymentMethods.length}{' '}
                  {translations.payment_types}
                </span>
                <i className={clsx(styles.triangle, styles.down)}></i>
                <div
                  className={styles.paymentTypePickerDropdown}
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className={styles.message}>{translations.select_payment_types}</div>

                  <div className={styles.paymentMethods}>
                    {newPaymentMethods
                      .slice(0, showMorePaymentMethods ? newPaymentMethods.length : 6)
                      .map((paymentMethod) => (
                        <div
                          key={paymentMethod.id}
                          className={styles.paymentMethod}
                          onClick={() => {
                            togglePreferredPaymentMethod(paymentMethod.id, true);
                          }}
                        >
                          <Checkbox
                            className={styles.checkbox}
                            isChecked={!!preferredPaymentMethods?.includes(paymentMethod.id)}
                          />
                          <div className={styles.name}>{paymentMethod.name}</div>
                        </div>
                      ))}
                  </div>

                  {newPaymentMethods.length > 6 && !showMorePaymentMethods ? (
                    <div
                      data-pw='paymentMethods_showMoreBtn'
                      className={styles.showMore}
                      onClick={() => setShowMorePaymentMethods(true)}
                    >
                      + {translations.show_more}
                    </div>
                  ) : null}

                  {showMorePaymentMethods ? (
                    <div
                      className={styles.showMore}
                      onClick={() => setShowMorePaymentMethods(false)}
                    >
                      - {translations.show_less}
                    </div>
                  ) : null}

                  <div className={styles.message}>{translations.tips}</div>

                  <div className={styles.applyContainer}>
                    <button
                      data-pw='paymentMethod_applyBtn'
                      onClick={() => {
                        setIsPaymentTypePickerExpanded(false);
                      }}
                      type='button'
                    >
                      {((translations.apply as string) || '').toLowerCase()}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button id='flightSearchFormButton' type='submit' tabIndex={2} onKeyDown={handleKeyDown}>
            {translations.search}
          </button>
        </div>
      </div>

      {flightComparisonProviders.length > 0 ? (
        <div className={styles.flightComparisonProviders}>
          <div className={styles.label}>
            {getComparisonProviderLabelText(flightComparisonProviders, translations)}
          </div>

          {flightComparisonProviders.map((flightComparisonProvider) => (
            <div
              key={flightComparisonProvider.provider.code}
              className={styles.flightComparisonProvider}
              onClick={() =>
                handleFlightComparisonProviderToggle(
                  flightComparisonProvider.provider.code,
                  !flightComparisonProvider.preChecked,
                )
              }
            >
              <Checkbox
                className={styles.checkbox}
                isChecked={flightComparisonProvider.preChecked}
              />
              {flightComparisonProvider.provider.name}
            </div>
          ))}
        </div>
      ) : null}
    </form>
  );
};

export default FlightSearchForm;
